import PopupLayout from 'components/global/PopupLayout'
import React from 'react'
import { Link } from 'react-router-dom'
import { setShowKycReminder } from 'redux/reducers/bookingReducer'

const KycReminderPopup = () => {
    return (
        <PopupLayout
        setShowPopup={setShowKycReminder}
        redux
        width={'2xl:w-[40%] lg:w-[50%] md:w-[65%] sm:w-[80%] w-full'}
        >
            <div className='flex flex-col gap-8'>
                <h3 className='text-center text-xl font-semibold '>
                    Complete your KYC
                </h3>
                <div className='flex items-center flex-col '>
                    <p className='text-center text-red-500'>
                        Dear user, it seems you haven't completed your KYC yet. Please note that your booking has been placed, but it will remain pending until your KYC is completed. To get your booking approved quickly, kindly complete your KYC as soon as possible
                    </p>
                    <div className="mt-10">
                        <Link  
                        to={`/kyc/step1`}
                        className='btn-primary py-2 px-4'>
                            Complete KYC
                        </Link>
                    </div>
                </div>
            </div>
        </PopupLayout>
    )
}

export default KycReminderPopup