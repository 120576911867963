import BackBtn from 'components/global/BackBtn';
import SmallCarImg from 'assets/images/smallCar.png';
import keysImg from 'assets/images/keys.png';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from 'react-query';
import fetcher from 'utils/fetcher';
import { useDispatch, useSelector } from 'react-redux';
import toastError from 'utils/toastError';
import Axios from 'config/api';
import { toast } from 'react-toastify';
import CarInfo from 'components/bookings/CarInfo';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';
import PickupInstructions from 'components/bookings/PickupInstructions';
import LocationLink from 'components/global/LocationLink';
import RequestStatus from 'components/global/RequestStatus';
import { ClipLoader } from 'react-spinners';
// import ReviewForm from 'components/bookings/ReviewForm';
import ReviewFormPopup from 'components/bookings/ReviewFormPopup';
import formatAmount from 'utils/formatAmount';
import isDateInRange from 'utils/isDateInRange';
import PickupPhotos from 'components/bookings/PickupPhotos';
import DropOffPhotos from 'components/bookings/DropOffPhotos';
import ReviewsPopup from 'components/popups/ReviewsPopup';
import confirmBox from 'utils/confirmBox';
import Breadcrumbs from 'components/global/Breadcrumbs';
import { setShowKycReminder } from 'redux/reducers/bookingReducer';
import KycReminderPopup from 'components/kyc/KycReminderPopup';

const crumbs = [
    { label: "Home", path: "/" },
    { label: "Bookings", path: "/bookings" },
    { label: "Booking Details",  },
]

const BookingDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { user } = useSelector(state => state.auth);
    const { showKycReminder } = useSelector(state => state.booking);
    const [booking , setBooking] = useState('');
    const [cancelLoading, setCancelLoading] = useState(false);
    const [showReviewPopup , setShowReviewPopup] = useState(false);
    const [showReviewsListPopup , setShowReviewsListPopup] = useState(false);

    const queryKey = ['fetch-booking-details' , id]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/booking/${id}` , user)
    });

    useEffect(() => {
        if(data) {
            setBooking(data?.data?.data?.doc);
        }
    } , [data]);

    useEffect(() => {
        if(user && !user.kyc && booking) {
            dispatch(setShowKycReminder(true))
        }
    } , [user , booking])

    const cancelBookingHandler = async () => {
        const message = 'Are you sure? You want to cancel this booking?';
        const onYesClick = async () => {
            try {
                setCancelLoading(true);
                const { data : { data : { message , doc } } } = await Axios.put(`/booking/cancel/${id}` , '' , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                toast.success(message);
                setBooking(doc);
                setCancelLoading(false);
            } catch (error) {
                setCancelLoading(false);
                toastError(error);
            }
        }
        confirmBox({ message , onYesClick })
    }


    return (
        <div>
            <div className='container mx-auto py-4 sm:px-0 px-4'>
                <Breadcrumbs crumbs={crumbs} />
                <div className='flex items-center py-6 justify-between gap-4 border-b-2 pb-2 border-secondary'>
                    <h1 className="section-heading text-gradient">
                        Booking Details
                    </h1>
                    <BackBtn />
                </div>
                {
                    isLoading
                    ?
                        <Loader />
                    : 
                    booking 
                    ? 
                        <>
                            <div className='mt-12 flex gap-6 md:flex-row flex-col'>
                                <div className='flex-[0.7] shadow-bg p-4'>
                                    <div className='flex items-center gap-2 rounded-md py-3 px-3 bg-green-100 border border-green-500'>
                                        <i className="uil uil-check-circle text-green-500"></i>
                                        <p className='text-sm text-dark'>Free cancellation up to 48 hours before pick-up</p>
                                    </div>
                                    <div>
                                        <CarInfo 
                                        carDetails={booking?.car}
                                        />
                                    </div>
                                    <div className='flex gap-3 items-center justify-between mt-6'>
                                        <div className='flex gap-3 items-center'>
                                            <div className='bg-blue-800 rounded-md py-1.5 px-2 text-sm text-pure'>
                                                {booking?.car?.ratingsAvg}
                                            </div>
                                            <div className='flex flex-col '>
                                                <strong className='text-sm font-bold'>
                                                    {
                                                        booking?.car?.ratingsCount === 0
                                                        ? 
                                                            'No reviews yet'
                                                        :
                                                        booking?.car?.ratingsAvg > 4 
                                                        ? 
                                                            'Excellent'
                                                        : 
                                                        booking?.car?.ratingsAvg > 3
                                                        ? 
                                                            'Very Good'
                                                        : 
                                                        booking?.car?.ratingsAvg > 2
                                                        ?
                                                            'Not Bad'
                                                        : 
                                                        booking?.car?.ratingsAvg > 1
                                                        ? 
                                                            'Not Recomended'
                                                        : 
                                                            'Not Recomended'
                                                    }
                                                </strong>
                                                <p 
                                                className='text-grayText underline text-sm cursor-pointer'
                                                onClick={() => setShowReviewsListPopup(true)}
                                                >
                                                    {booking?.car?.ratingsCount} {booking?.car?.ratingsCount > 1 ? 'Reviews' : 'review'}
                                                </p>
                                            </div>
                                        </div>
                                        {
                                            user && booking?.status === 'completed' && 
                                            <button 
                                            className='text-sm underline'
                                            onClick={() => setShowReviewPopup(true)}
                                            >
                                                Write a review
                                            </button>
                                        }
                                    </div>
                                    <div className='border-t pt-6 mt-6'>
                                        <PickupInstructions
                                        carDetails={booking?.car}
                                        />
                                    </div>
                                    <div className='mt-6 pt-6 border-t  flex sm:items-center justify-between sm:flex-row flex-col gap-6'>
                                        <div>
                                            <h6 className='text-lg font-semibold'>
                                                Great Choice
                                            </h6>
                                            <ul className='grid sm:grid-cols-2 grid-cols-1 lg:gap-x-20 md:gap-x-12 gap-x-8 gap-y-1 mt-4'>
                                                <li className='flex items-center gap-2 '>
                                                    <i className="uil uil-check text-green-500 text-lg"></i>
                                                    <span className='text-sm text-dark font-medium'>Customer rating: {booking?.car?.ratingsAvg} / 5</span>
                                                </li>
                                                <li className='flex items-center gap-2 '>
                                                    <i className="uil uil-check text-green-500 text-lg"></i>
                                                    <span className='text-sm text-dark font-medium'>Most popular fuel policy</span>
                                                </li>
                                                <li className='flex items-center gap-2 '>
                                                    <i className="uil uil-check text-green-500 text-lg"></i>
                                                    <span className='text-sm text-dark font-medium'>Short queues</span>
                                                </li>
                                                <li className='flex items-center gap-2 '>
                                                    <i className="uil uil-check text-green-500 text-lg"></i>
                                                    <span className='text-sm text-dark font-medium'>Easy to find counter</span>
                                                </li>
                                                <li className='flex items-center gap-2 '>
                                                    <i className="uil uil-check text-green-500 text-lg"></i>
                                                    <span className='text-sm text-dark font-medium'>Helpful counter staff</span>
                                                </li>
                                                   <li className='flex items-center gap-2 '>
                                                    <i className="uil uil-check text-green-500 text-lg"></i>
                                                    <span className='text-sm text-dark font-medium'>Free Cancellation</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <img 
                                            src={keysImg} 
                                            alt="keys" 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-[0.3]'>
                                    <div className="shadow-bg p-4">
                                        <h6 className='sm:text-lg text-base sm:font-bold font-semibold'>
                                        Pick up & Drop-off Location
                                        </h6>
                                        <div className='mt-4'>
                                            <div>
                                                <div className='flex gap-4'>
                                                    <div className='aspect-w-1 aspect-h-1'>
                                                        <div className="relative w-4 h-4 rounded-full border-2 border-black ">
                                                            <div className="absolute top-5 left-1/2 -translate-x-1/2 w-[1px] h-[50px] bg-grayText"></div>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col gap-1.5'>
                                                        <small>
                                                            {
                                                            moment(booking?.pickupDate).format('DD MMM YYYY hh:mm a')
                                                            } 
                                                        </small>
                                                        <strong>
                                                            <LocationLink
                                                            location={booking?.car?.location}
                                                            />
                                                        </strong>
                                                    </div>
                                                </div>
                                                <div className='flex gap-4 mt-8'>
                                                    <div className='aspect-w-1 aspect-h-1'>
                                                        <div className="relative w-4 h-4 rounded-full border-2 border-black ">
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col gap-1.5'>
                                                    <small>
                                                            {
                                                            moment(booking?.returnDate).format('DD MMM YYYY hh:mm a')
                                                            } 
                                                        </small>
                                                        <strong>
                                                            <LocationLink
                                                            location={booking?.car?.dropOffLocation || booking?.car?.location}
                                                            />
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shadow-bg p-4 mt-6 flex flex-col gap-4">
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Total Duration
                                            </h6>
                                            <div className='flex gap-2'>
                                                <span>
                                                    {booking?.duration?.days}
                                                    {
                                                        booking?.duration?.days > 1 ? ' days' : ' day'
                                                    }    
                                                </span>
                                                <span>
                                                    {booking?.duration?.hours}
                                                    {
                                                        booking?.duration?.hours > 1 ? ' hours' : ' hour'
                                                    }    
                                                </span>
                                                <span>
                                                    {booking?.duration?.minutes}
                                                    {
                                                        booking?.duration?.minutes > 1 ? ' mins' : ' min'
                                                    }    
                                                </span> 
                                            </div>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Payment Status : 
                                            </h6>
                                            <RequestStatus status={booking?.paymentStatus} />
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Vehicle Rent : 
                                            </h6>
                                            <p>
                                                ₹ {formatAmount(booking?.vehicleRent)}
                                            </p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Insurance Cost : 
                                            </h6>
                                            <p>
                                                ₹ {formatAmount(booking?.insuranceCost)}
                                            </p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Driver Rent : 
                                            </h6>
                                            <p>
                                                ₹ {formatAmount(booking?.driverRent)}
                                            </p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Security Fee : 
                                            </h6>
                                            <p>
                                                ₹ {formatAmount(booking?.securityFee)}
                                            </p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                GST : 
                                            </h6>
                                            <p>
                                                ₹ {formatAmount(booking?.GST)}
                                            </p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Discount : 
                                            </h6>
                                            <p>
                                                ₹ {formatAmount(booking?.discountDetails?.discount || 0)}
                                            </p>
                                        </div>
                                        
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Grand Total : 
                                            </h6>
                                            <p className='text-lg font-semibold'>
                                                ₹ &nbsp; 
                                                {formatAmount(booking?.totalPrice)}
                                            </p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Paid Amount : 
                                            </h6>
                                            <p className='font-semibold text-green-500'>
                                                ₹ {formatAmount(booking?.amountPaid)}
                                            </p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium '>
                                                Remaining Amount : 
                                            </h6>
                                            <p className='font-semibold text-red-500'>
                                                ₹ {formatAmount(booking?.remainingAmount)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='mt-6 shadow-bg p-4'>
                                        <div className='flex items-center justify-between text-sm'>
                                            <h6 className='font-semibold'>Current Status</h6>
                                            <RequestStatus
                                            status={booking?.status}
                                            />
                                        </div>
                                        {
                                            booking?.status !== 'cancelled'
                                            ? 
                                                (booking?.status === 'pending' || booking?.status === 'accepted') && isDateInRange(booking?.createdAt , booking?.pickupDate)
                                                ?
                                                    <div className="mt-12 flex items-end justify-end">
                                                        <button 
                                                        className="border font-medium bg-red-500 text-pure duration-300  rounded-md py-3 px-8 disabled:cursor-not-allowed"
                                                        title='cancel this booking'
                                                        onClick={cancelBookingHandler}
                                                        disabled={cancelLoading || booking?.status === 'cancelled'}
                                                        >
                                                            {
                                                                cancelLoading
                                                                ?
                                                                    <ClipLoader 
                                                                    size={20} 
                                                                    color='white' 
                                                                    />
                                                                : 
                                                                    'Cancel Booking'
                                                            }
                                                        </button>
                                                    </div>
                                                : 
                                                booking?.status === 'completed'
                                                ? 
                                                    <p 
                                                    className='mt-12 text-green-500 font-semibold'
                                                    >
                                                        This booking is completed
                                                    </p>
                                                : 
                                                booking?.status === 'picked' && isDateInRange(booking?.pickupDate , booking?.returnDate)
                                                ? 
                                                    <div className="mt-12 flex items-end justify-end">
                                                        <Link 
                                                        className="btn-primary py-2 px-6"
                                                        to={`/bookings/extend-booking/${booking?._id}`}
                                                        >
                                                            Extend Booking
                                                        </Link>
                                                    </div>
                                                :
                                                    ''
                                            :
                                                <div className='mt-12 text-red-500 font-semibold'>
                                                    {
                                                        booking?.cancelledBy?._id === user?._id 
                                                        ?
                                                        'This Booking is cancelled by you.'
                                                        :
                                                        'This Booking is cancelled by Admin or seller.' 

                                                    }
                                                </div>
                                        }


                                    </div>
                                </div>

                            </div>

                            {/* {
                                user && booking?.status === 'completed' &&
                                <div 
                                className='pt-8'
                                id='review-form'
                                >        
                                    <ReviewForm 
                                    carId={booking?.car?._id}
                                    />
                                </div>
                            } */}
                        </>
                    : 
                        <ItemNotFound message='Booking not found.' />
                }
                {
                    booking && booking?.pickupPhotos && Object.values(booking?.pickupPhotos)?.length > 0 && 
                    <PickupPhotos booking={booking} />
                }
                {
                    booking && booking?.dropOffPhotos && Object.values(booking?.dropOffPhotos)?.length > 0 && 
                    <DropOffPhotos booking={booking} />
                }
            </div>
            { 
                showReviewPopup && 
                <ReviewFormPopup
                showReviewPopup={showReviewPopup}
                setShowReviewPopup={setShowReviewPopup}
                carId={booking?.car?._id}
                />
            
            }
            {
                showReviewsListPopup && 
                <ReviewsPopup
                carId={booking?.car?._id} 
                setShowReviewsPopup={setShowReviewsListPopup}
                />
            }

            {
                showKycReminder && <KycReminderPopup />
            }
           
        </div>
    )
}

export default BookingDetails