const faqsData = [
    {
        question: "What types of cars are available for luxury car rentals in India?",
        answer: "At Rent N Drive, we offer a wide selection of luxury vehicles, including high-end sedans, SUVs, and convertibles for a car on rent in India. Our luxury car rentals in India are perfect for special occasions, business events, or simply enjoying a premium driving experience."
    },
    {
        question: "Can I book a self drive car rental in India online?",
        answer: "Yes, you can easily book a self drive car rental in India through our website. Our online booking system allows you to choose your preferred vehicle, pick-up and drop-off locations, and rental period in just a few clicks."
    },
    {
        question: "What is the cheapest car hire option in India?",
        answer: "Rent N Drive offers several affordable options for those looking for cheap car hire in India. Our economy cars provide great value for money, combining fuel efficiency with comfort and reliability."
    },
    {
        question: "Are corporate car rental services in India available?",
        answer: "Absolutely! Rent N Drive offers tailored corporate car rental services in India to meet the needs of businesses. Whether you need vehicles for a day or a longer-term rental, we provide flexible solutions to suit your company’s requirements."
    },
    {
        question: "Can I rent a car in India with a driver?",
        answer: "Yes, Rent N Drive offers the option to rent a car in India with a driver. This service is ideal for those who prefer to relax while a professional chauffeur takes care of the driving, whether for business or leisure purposes."
    }
];

module.exports = faqsData;
