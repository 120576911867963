import Axios from "config/api";
import { setCalculateLoading, setCouponLoading } from "redux/reducers/bookingReducer";
import calculateCODAmountToPayOnline from "utils/calculateCODAmoutToPayOnline";
import toastError from "utils/toastError";

export const reApplyCoupon = ({ couponCode , bookingAmount , setBookingData , setDiscountDetails , carId , prevData , settings }) => async (dispatch , getState) => {

    const body = { couponCode , bookingAmount }
    try {
        dispatch(setCouponLoading(true));
        const { data : { data : { coupon , actualAmount , discountAmount , discount } } } = await Axios.post('/coupon/apply' , body , {
            headers : {
                Authorization : `Bearer ${getState()?.auth?.user?.token}`
            }
        });
        const discountData = {
            coupon ,
            actualBookingAmount : actualAmount ,
            afterDiscountAmount : discountAmount ,
            isCouponApplied : true , 
            discount 
        };

        const updatedData = {...prevData , totalPrice : discountAmount };
        if(updatedData?.paymentMethod === 'COD') {
            const amountToPayOnline = calculateCODAmountToPayOnline({
                bookingData : updatedData , 
                discountDetails : discountData , 
                settings
            });             
            updatedData.amountToPayOnline = amountToPayOnline;
        }else {
            updatedData.amountToPayOnline = discountAmount + settings?.securityFee;
        }
        localStorage.setItem('bookingDetails' , JSON.stringify({
            ...updatedData , 
            pickupDate: updatedData?.pickupDate?.toISOString() || null ,
            returnDate: updatedData?.returnDate?.toISOString() || null , 
            carId 
        }));
        setBookingData(updatedData);
        
        setDiscountDetails(discountData);
        localStorage.setItem('discountDetails' , JSON.stringify({...discountData , carId  }));
        dispatch(setCouponLoading(false));
    } catch (error) {
        dispatch(setCouponLoading(false));
        toastError(error)
    }
}

export const calculateBooking = ({ bookingData , setBookingData , discountDetails , setDiscountDetails , carId , setCouponCode }) => async (dispatch , getState) => {
    
    const body = { bookingData , discountDetails , carId };

    try {
        dispatch(setCalculateLoading(true));
        let { data : { data : { updatedBookingData , updatedDiscountData } } } = await Axios.post('/booking/calculate' , body );

        updatedBookingData.pickupDate = new Date(updatedBookingData?.pickupDate)
        updatedBookingData.returnDate = new Date(updatedBookingData?.returnDate)

        setBookingData(updatedBookingData);    

        
        localStorage.setItem('bookingDetails' , JSON.stringify({
            ...updatedBookingData , 
            pickupDate: updatedBookingData?.pickupDate?.toISOString() || null ,
            returnDate: updatedBookingData?.returnDate?.toISOString() || null , 
            carId 
        }));
        if(updatedDiscountData) {
            setDiscountDetails(updatedDiscountData);
            localStorage.setItem('discountDetails' , JSON.stringify({...updatedDiscountData , carId  }));
        }
        if(setCouponCode) {
            setCouponCode(updatedDiscountData?.coupon?.code || '')
        }
        dispatch(setCalculateLoading(false));
    } catch (error) {
        dispatch(setCalculateLoading(false));
        toastError(error)
    }
}