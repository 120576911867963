import { baseURL } from 'config/api'
import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { calculateBooking } from 'redux/actions/bookingActions'



const Insurance = ({ insurancePlans , setBookingData , bookingData , discountDetails , setDiscountDetails }) => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const handleClick = (item) => {
        if (!bookingData?.pickupDate || !bookingData?.returnDate) return;
        let updatedBookingData = {...bookingData , insurance : item?._id , insuranceCost : item?.price }
        setBookingData(updatedBookingData);
        dispatch(calculateBooking({
            bookingData : updatedBookingData ,
            setBookingData ,
            discountDetails  ,
            setDiscountDetails ,
            carId : id ,
        }))
    }

    const isDisabled = !bookingData?.pickupDate || !bookingData?.returnDate;

    return (
        insurancePlans?.length > 0 
        ?
            <div className={`border p-2 rounded-md `}>
                <div>
                    <h6 className='font-medium'>Travel with confidence</h6>
                    <p className='text-[13px] text-gray-600'>Choose a plan and secure your trip</p>
                </div>
                <div className='grid grid-cols-3 sm:gap-4 gap-2 mt-2'>
                    {
                        insurancePlans?.map(item => (
                            <div 
                            className={`
                                rounded-md p-3 flex flex-col items-center gap-1 border cursor-pointer
                                ${bookingData?.insurance === item?._id ? 'border-2 border-primary' : ''}
                                ${isDisabled ? 'opacity-70 cursor-not-allowed' : 'hover:border-primary'} 
                                 
                            `}
                            key={item?._id}
                            onClick={() => handleClick(item)}
                            >
                                <p className='text-primary font-medium text-sm'>
                                    {item?.name}
                                </p>
                                <div>
                                    <img 
                                    src={baseURL + '/insurance/' + item?.image} 
                                    alt={item?.name} 
                                    className='w-[50px] h-[50px] rounded-full object-cover border flex-shrink-0'
                                    />
                                </div>
                                <p className='font-medium'>
                                    ₹{item?.price}
                                </p>
                                <p 
                                className='sm:text-[13px] text-[10px]'
                                data-tooltip-id="insurance-desc"
                                data-tooltip-content={`${item?.description}`}
                                >
                                    {item?.description?.slice(0,20)}...

                                    <Tooltip id='insurance-desc' style={{ width : 250 , fontSize : 12 }} />
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>
        : 
            ''
    )
}

export default memo(Insurance)