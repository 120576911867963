import { createSlice } from '@reduxjs/toolkit';

const bookingSlice = createSlice({
    name : 'booking' ,
    initialState : {
        couponLoading : false ,
        calculateLoading : false ,
        showKycReminder : false 
    } ,
    reducers : {
        setCouponLoading(state , action) {
            state.couponLoading = action.payload;
        } ,
        setCalculateLoading(state , action) {
            state.calculateLoading = action.payload;
        } ,
        setShowKycReminder (state , action) {
            state.showKycReminder = action.payload;
        }
    }
});

export const { setCouponLoading , setCalculateLoading , setShowKycReminder } = bookingSlice.actions;
export default bookingSlice.reducer;


